
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Banner from '~/components/Banner.vue'

@Component({
  components: {
    Banner,
    Features: () => import('./Features.vue')
  }
})
export default class extends Vue {
  @Prop() isHideSidebar!: boolean
  @Prop() isShowBanner!: boolean
}
